import {
  Alert,
  AlertIcon,
  Box,
  Button,
  css,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Textarea,
} from "@chakra-ui/core";
import PageLayout from "@components/PageLayout";
import htmlSerializer from "@src/gatsby/htmlSerializer";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import React, { useState } from "react";
import linkResolver from "../linkResolver";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function ContactPage(props) {
  const [state, setState] = React.useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const validate = () => {
    if (!state.email && !state.phone) {
      setValidationErrors({
        ...validationErrors,
        email: "E-post eller telefonnummer krväs",
        phone: "E-post eller telefonnummer krväs",
      });

      return false;
    } else {
      setValidationErrors({});
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validate()) {
      return false;
    }

    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setFormSubmitted(true);
        form.reset();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <PageLayout title={"Kontakt"}>
      {formSubmitted && (
        <Alert status="success" bordrerRadius="md" fontSize="sm" mb={4}>
          <AlertIcon />
          Formulär skickat! Jag hör av mig inom kort!
        </Alert>
      )}
      <Grid
        pb={{ md: 12, sm: "8rem" }}
        gridTemplateColumns={{ md: "repeat(2, 1fr)", sm: "repeat(1, 1fr)" }}
        gridGap="2em"
        styles={css`
          ${Input} {
            border: none;
            background: red;
          }
        `}
      >
        <Flex flex="1" order={{ md: "inherit", sm: 1 }}>
          <Flex
            flexDir="column"
            flex="1"
            as="form"
            // action="/kontakt"
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot={"bot-field"}
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />

            <Box display="none">
              <label>
                Fyll inte i denna:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </Box>

            <FormControl mb={4}>
              <FormLabel fontSize="xs" htmlFor="name">
                Namn
              </FormLabel>
              <Input
                fontSize="xs"
                px={3}
                type="text"
                id="name"
                name="name"
                aria-describedby="name-helper-text"
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4} isInvalid={!!validationErrors.email}>
              <FormLabel fontSize="xs" htmlFor="email">
                E-post
              </FormLabel>
              <Input
                fontSize="xs"
                px={3}
                type="email"
                id="email"
                name="email"
                isInvalid={!!validationErrors.email}
                errorBorderColor="red.300"
                aria-describedby="email-helper-text"
                onChange={handleChange}
              />
              <FormErrorMessage fontSize="2xs">
                {validationErrors.email}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={4} isInvalid={!!validationErrors.phone}>
              <FormLabel fontSize="xs" htmlFor="pnone">
                Telefon
              </FormLabel>
              <Input
                fontSize="xs"
                px={3}
                type="tel"
                id="phone"
                isInvalid={!!validationErrors.phone}
                errorBorderColor="red.300"
                name="phone"
                aria-describedby="phone-helper-text"
                onChange={handleChange}
              />
              <FormErrorMessage fontSize="2xs">
                {validationErrors.phone}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel fontSize="xs" htmlFor="email">
                Meddelande
              </FormLabel>

              <Textarea
                id="message"
                name="message"
                fontSize="xs"
                px={3}
                onChange={handleChange}
              />
              {/* <FormHelperText id="email-helper-text">
            We'll never share your email.
          </FormHelperText> */}
            </FormControl>
            <Button type="submit" bg="blue.300" size="sm" color="white">
              Skicka
            </Button>
          </Flex>
        </Flex>
        <Flex flex="1" flexDir="column" pt={5}>
          <RichText
            render={props.data.prismicPage.data.page_content.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializer}
          />
          {/* {JSON.stringify(props.data.prismicPage.data.page_content.text)} */}
        </Flex>
      </Grid>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query ContactQuery {
    prismicPage(uid: { eq: "kontakt" }) {
      uid
      data {
        page_title {
          text
        }
        page_content {
          raw
          text
        }
      }
    }
  }
`;

export default ContactPage;
