import React from "react";
import { Heading } from "@chakra-ui/core";

import { Layout, Title } from "@components";
import Wrapper from "@components/Wrapper";

function PageLayout({ title, children }) {
  return (
    <Layout page>
      <Wrapper py={1} px={4}>
        <Heading as="h1" size="xl" mb={8} textAlign="center">
          {title}
        </Heading>
        {children}
      </Wrapper>
    </Layout>
  );
}

export default PageLayout;
